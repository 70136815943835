import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CleaningStatusType, RoomType, RoomUsageStatusType, ReservationType, StaffType, CheckListType } from '@/constants/cleaning-manager'
import {
  fetchCleaningManagerSetting,
  fetchCleaningStaff,
  fetchRoomDetail,
  putCleaningRoomCheckList,
  updateGuestRoomStatusForCleaningManager,
  UpdateGuestRoomStatusForCleaningManagerRequestType,
} from '@/apis/aipass'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { useForm } from 'react-hook-form'
import dayjs from 'dayjs'
import { css } from '@emotion/core'
import { hasCleaningMemo, isCleaningMemo } from '@/models/reservation/reservation-note'
import { errorHandler } from '@/libs/errors'
import { HeaderSp } from '@/components/organisms/cleaner/sp/header-sp'
import { ReportForm } from '@/components/organisms/cleaner/sp/report-form'
import { LoadingFull } from '@/components/molecules/loading-full'
const queryString = require('query-string')

export const CleaningManagerDetail: React.FC = () => {
  const [room, setRoom] = useState<RoomType>()
  const [isLoading, setIsLoading] = useState(false)
  const [staffList, setStaffList] = useState<StaffType[]>([])
  const [checkList, setCheckList] = useState<CheckListType[]>([])
  const [checkedIdList, setCheckedIdList] = useState<number[]>([])

  const history = useHistory()
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const params = useParams<{ roomId: string | undefined }>()
  const { roomId } = params
  const selectedDate = queryParams.dateOf ? dayjs(queryParams.dateOf) : dayjs()

  const { t } = useTranslation()

  const useFormReturn = useForm<Required<UpdateGuestRoomStatusForCleaningManagerRequestType>>({
    defaultValues: {
      guestRoomId: roomId,
      dateOf: queryParams?.dateOf || '',
      staffReport: '',
      cleaningStatus: CleaningStatusType.Unused,
      roomUsageStatus: RoomUsageStatusType.Unused,
      images: [],
    },
  })
  const { getValues, reset, setValue } = useFormReturn

  const fetchCheckList = async () => {
    setIsLoading(true)
    const checkList = (await fetchCleaningManagerSetting()) || []
    setCheckList(checkList)
    setIsLoading(false)
  }

  const fetchStaffs = async () => {
    setIsLoading(true)
    const staffs = await fetchCleaningStaff().then(res => res || [])
    setStaffList(staffs)
    setIsLoading(false)
  }

  const renderCleaningStatusButton = () => {
    switch (room?.cleaningStatus) {
      case CleaningStatusType.NotCleaning:
      case CleaningStatusType.Unused:
        return (
          <div css={buttonStyle} style={{ background: '#3E85CC' }} onClick={() => changeCleaningStatus(CleaningStatusType.Cleaned)}>
            {t('cleaningManager.Changed to cleaned')}
          </div>
        )
      case CleaningStatusType.Cleaned:
        return (
          <div css={buttonStyle} style={{ background: '#7DC931' }} onClick={() => changeCleaningStatus(CleaningStatusType.Inspected)}>
            {t('cleaningManager.Changed to inspected')}
          </div>
        )
      default:
        return (
          <div css={[buttonStyle, { opacity: 0.5 }]} style={{ background: '#3E85CC' }}>
            {t('cleaningManager.Changed to cleaned')}
          </div>
        )
    }
  }

  const computedChildPaxText = (roomPax: ReservationType['roomPax']): string => {
    return (
      [
        roomPax.childA ? t('cleaningManager.ChildA', { pax: roomPax.childA }) : undefined,
        roomPax.childB ? t('cleaningManager.ChildB', { pax: roomPax.childB }) : undefined,
        roomPax.childC ? t('cleaningManager.ChildC', { pax: roomPax.childC }) : undefined,
        roomPax.childD ? t('cleaningManager.ChildD', { pax: roomPax.childD }) : undefined,
        roomPax.childE ? t('cleaningManager.ChildE', { pax: roomPax.childE }) : undefined,
        roomPax.childF ? t('cleaningManager.ChildF', { pax: roomPax.childF }) : undefined,
        roomPax.childOther ? t('cleaningManager.ChildOther', { pax: roomPax.childOther }) : undefined,
      ]
        .filter(t => !!t)
        .join(t('cleaningManager.PaxSeparate')) || '-'
    )
  }

  const computedAdultPaxText = (roomPax: ReservationType['roomPax']): string => {
    return (
      [
        roomPax.male ? t('cleaningManager.Male', { pax: roomPax.male }) : undefined,
        roomPax.female ? t('cleaningManager.Female', { pax: roomPax.female }) : undefined,
      ]
        .filter(t => !!t)
        .join(t('cleaningManager.PaxSeparate')) || '-'
    )
  }

  const clearAllCheckList = async () => {
    if (!room) {
      return
    }

    checkedIdList.forEach(async id => {
      await putCleaningRoomCheckList({
        guestRoomId: room.guestRoomId,
        dateOf: selectedDate.format('YYYY-MM-DD'),
        checkListId: id,
        isChecked: false,
      })
    })
    setCheckedIdList([])
  }

  const changeCleaningStatus = async (newStatus: CleaningStatusType) => {
    if (!room) {
      return
    }

    try {
      setIsLoading(true)
      if (newStatus === CleaningStatusType.NotCleaning) {
        clearAllCheckList()
      }
      await updateGuestRoomStatusForCleaningManager({
        guestRoomId: room.guestRoomId,
        dateOf: selectedDate,
        staffReport: undefined,
        cleaningStatus: newStatus,
        cleaningStaffId: undefined,
        roomUsageStatus: undefined,
      })

      history.push('/cleaning')
    } catch (error) {
      errorHandler(error)
    }
  }

  const changeRoomUsageStatus = async (newStatus: RoomUsageStatusType) => {
    if (!room) {
      return
    }

    setValue('roomUsageStatus', newStatus)

    await updateGuestRoomStatusForCleaningManager({
      guestRoomId: room.guestRoomId,
      dateOf: selectedDate,
      staffReport: undefined,
      cleaningStatus: undefined,
      cleaningStaffId: undefined,
      roomUsageStatus: newStatus,
    })
  }

  const save = async () => {
    if (!room) {
      return
    }

    const values = getValues()
    await updateGuestRoomStatusForCleaningManager({
      guestRoomId: room.guestRoomId,
      dateOf: selectedDate,
      staffReport: values.staffReport,
      cleaningStatus: values.cleaningStatus,
      cleaningStaffId: values.cleaningStaffId,
      roomUsageStatus: values.roomUsageStatus,
      images: values.images,
    })

    history.block(() => {})
    history.push('/cleaning')
  }

  const saveCheckList = async (checkListId: number, isChecked: boolean) => {
    if (!room) {
      return
    }

    let newCheckedIdList = isChecked ? [...checkedIdList, checkListId] : checkedIdList.filter(i => i !== checkListId)

    setCheckedIdList(newCheckedIdList)
    putCleaningRoomCheckList({
      guestRoomId: room.guestRoomId,
      dateOf: selectedDate.format('YYYY-MM-DD'),
      checkListId: checkListId,
      isChecked: isChecked,
    })
  }

  useEffect(() => {
    if (!selectedDate) {
      return
    }

    setIsLoading(true)
    fetchRoomDetail(roomId, selectedDate.format('YYYY-MM-DD'))
      .then(data => {
        setRoom(data)
        setCheckedIdList(data.checkList.filter(({ isChecked }) => isChecked).map(({ id }) => id))
      })
      .catch(e => {})
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    fetchCheckList()
  }, [])

  useEffect(() => {
    fetchStaffs()
  }, [])

  useEffect(() => {
    if (!room) {
      return
    }
    reset({
      ...getValues(),
      staffReport: room.staffReport,
      cleaningStaffId: room.cleaningStaffId,
      cleaningStatus: room.cleaningStatus,
      roomUsageStatus: room.roomUsageStatus,
      images: room.photos,
    })
  }, [room])

  const roomCheckinAt = room?.nextReservation?.roomCheckinAt ? dayjs(room?.nextReservation?.roomCheckinAt) : null

  return (
    <>
      {isLoading ? (
        <LoadingFull isLoading={isLoading} />
      ) : (
        <div>
          <HeaderSp title={t('Cleaning management')} />
          <div css={roomNumberHeaderStyle}>
            <p className="room-number">{room?.roomNumber}</p>
            <div css={selectStyle}>
              <select
                onChange={e => {
                  changeCleaningStatus(Number(e.target.value) as CleaningStatusType)
                }}
                value={getValues().cleaningStatus}
              >
                <option value={CleaningStatusType.NotCleaning}>{t('cleaningManager.Dirty')}</option>
                <option value={CleaningStatusType.Cleaned}>{t('cleaningManager.Clean')}</option>
                <option value={CleaningStatusType.Inspected}>{t('cleaningManager.Inspected')}</option>
                <option value={CleaningStatusType.DoNotDisturb}>{t('cleaningManager.Do not Disturb')}</option>
                <option value={CleaningStatusType.NotRequired}>{t('cleaningManager.No Cleaning')}</option>
                <option value={CleaningStatusType.Unused}>-</option>
              </select>

              <select
                className="room-usage"
                onChange={e => {
                  changeRoomUsageStatus(Number(e.target.value) as RoomUsageStatusType)
                }}
                value={getValues().roomUsageStatus}
              >
                <option value={RoomUsageStatusType.Unused}>{t('cleaningManager.Vacant')}</option>
                <option value={RoomUsageStatusType.Stay}>{t('cleaningManager.Occupied')}</option>
                <option value={RoomUsageStatusType.Away}>{t('cleaningManager.Out')}</option>
                <option value={RoomUsageStatusType.CheckOutScheduled}>{t('cleaningManager.Checkout Soon')}</option>
                <option value={RoomUsageStatusType.CheckedOut}>{t('cleaningManager.Done Checkout')}</option>
              </select>
            </div>
          </div>

          {renderCleaningStatusButton()}

          <div css={tabBlockStyle}>
            <Tabs>
              <TabList>
                <Tab>{t('Share')}</Tab>
                <Tab>{t('Reporting')}</Tab>
              </TabList>

              <TabPanel>
                <div style={{ padding: '32px 24px' }}>
                  <div style={{ marginBottom: 24 }}>
                    <div css={[inlineContent, shareContentStyle]}>
                      <p className="title">{t('cleaningManager.Cleaning Instructions')}</p>
                      {(room?.adminComment ?? '').length > 0 && (
                        <img src={require('@/static/images/cleaning_manager/icon_instruction.svg')} alt="" style={{ paddingLeft: '4px' }} />
                      )}
                    </div>
                    <p css={adminCommentTextStyle}>{room?.adminComment || '-'}</p>
                  </div>

                  <div css={[shareContentStyle, { paddingBottom: 18 }]}>
                    <p className="title">{t('cleaningManager.Guest Information')}</p>
                  </div>
                  <div css={inlineContent} style={{ alignItems: 'flex-start' }}>
                    <img src={require('@/static/images/cleaning_manager/icon_date.svg')} alt="" />
                    <div css={reservationDetailStyle}>
                      <div className="title" style={{ paddingBottom: '4px' }}>
                        {roomCheckinAt
                          ? `${roomCheckinAt.format(t('cleaningManager.MM-DD(ddd)'))} ${t('cleaningManager.Night', {
                              nights: room?.nextReservation?.nights,
                            })} ${` - ${selectedDate.startOf('day').diff(roomCheckinAt.startOf('day'), 'day') + 1}泊目`}`
                          : '-'}
                      </div>
                      <div style={{ fontSize: 12, color: '#676767' }}>
                        {roomCheckinAt ? `${t('cleaningManager.Estimated Time of Arrival')} : ${roomCheckinAt.format('HH:mm')}` : '-'}
                      </div>
                    </div>
                  </div>

                  <div css={inlineContent} style={{ alignItems: 'flex-start', paddingTop: 16 }}>
                    <img src={require('@/static/images/reservation-note/icon_cleaning.svg')} alt="" />
                    <div css={reservationDetailStyle}>
                      <div css={shareContentStyle}>
                        <p className="title">
                          {hasCleaningMemo(room?.nextReservation?.notes) ? t('cleaningManager.Cleaning Remarks') : '-'}
                        </p>
                        {hasCleaningMemo(room?.nextReservation?.notes) && (
                          <img
                            src={require('@/static/images/cleaning_manager/icon_instruction.svg')}
                            alt=""
                            style={{ paddingLeft: '4px' }}
                          />
                        )}
                      </div>
                      <p style={{ lineHeight: '1.5', fontSize: 14 }}>
                        {room?.nextReservation?.notes.find(({ category }) => isCleaningMemo(category))?.memo ?? '-'}
                      </p>
                    </div>
                  </div>

                  <div css={inlineContent} style={{ alignItems: 'flex-start', paddingTop: 16 }}>
                    <img src={require('@/static/images/cleaning_manager/icon_people.svg')} alt="" />
                    <div css={reservationDetailStyle}>
                      <div css={shareContentStyle} style={{ paddingBottom: '4px', lineHeight: 1 }}>
                        <p className="title">
                          {room?.nextReservation?.roomPax
                            ? t('cleaningManager.Persons', { pax: room?.nextReservation?.roomPax.total })
                            : '-'}
                        </p>
                      </div>
                      <div>
                        {room?.nextReservation?.roomPax ? `${t('Adult')} : ${computedAdultPaxText(room.nextReservation.roomPax)}` : '-'}
                      </div>
                      <div>
                        {room?.nextReservation?.roomPax ? `${t('Child')} : ${computedChildPaxText(room.nextReservation.roomPax)}` : '-'}
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                {room && (
                  <ReportForm
                    checkedIdList={checkedIdList}
                    saveCheckList={saveCheckList}
                    onSave={save}
                    useFormReturn={useFormReturn}
                    checkList={checkList}
                    staffList={staffList}
                  />
                )}
              </TabPanel>
            </Tabs>
          </div>
        </div>
      )}
    </>
  )
}

const roomNumberHeaderStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: 24,
  borderBottom: '1px solid #F2F2F2',
  '.room-number': {
    color: '#272727',
    fontWeight: 'bold',
    fontSize: 18,
  },
})

const selectStyle = css({
  display: 'flex',
  select: {
    width: 'max-content',
    height: 18,
    color: '#676767',
    border: 'none',
    background: 'transparent',
    appearance: 'none',
    backgroundImage: `url(${require('@/static/images/arrow_gray.svg')})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '90% center',
    backgroundColor: '#FFFFFF',
    display: 'block',
    fontSize: '14px',
    fontWeight: 'bold',
    '&.room-usage': {
      marginLeft: 16,
      paddingLeft: 16,
      borderLeft: '1px solid #CCCCCC',
      fontSize: 12,
    },
  },
})

const tabBlockStyle = css({
  '.react-tabs__tab-list': {
    display: 'flex',
    border: 'none',
  },
  '.react-tabs__tab': {
    width: '50%',
    border: 'none',
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 'bold',
    color: '#AFAFAF',
    paddingBottom: 14,
    '&--selected': {
      color: '#F2A40B',
      borderBottom: '2px solid #F2A40B',
    },
  },
})

const shareContentStyle = css({
  '.title': {
    fontSize: 14,
    fontWeight: 'bold',
  },
})

const adminCommentTextStyle = css({
  whiteSpace: 'pre-line',
  fontSize: 14,
  lineHeight: '22px',
  letterSpacing: '0.7px',
  color: '#272727',
})

const inlineContent = css({
  display: 'flex',
  alignItems: 'center',
})

const reservationDetailStyle = css({
  paddingLeft: '16px',
})

const buttonStyle = css({
  margin: 24,
  paddingBlock: 12,
  borderRadius: 6,
  textAlign: 'center',
  color: '#fff',
})
