import React, { useEffect, useMemo, useState } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

// constants
import { wholeContainerStyle, mainColumnStyle, mainContainerStyle } from '@/constants/layout'

// components
import { Header } from '@/components/organisms/header'
import { SideMenu } from '@/components/organisms/side-menu'
import { LoadingFull } from '@/components/molecules/loading-full'
import dayjs from 'dayjs'
import { fetchMonthlyStaff, fetchMonthSummary, toggleCleaningStaffShift } from '@/apis/aipass'
import { StaffType } from '@/constants/cleaning-manager'
import { errorHandler } from '@/libs/errors'
import { DateField } from '@/components/molecules/date-field'
import { ScheduleList, ScheduleType } from '@/components/organisms/cleaning-manager/schedule'

export const Schedule = () => {
  const [dateOf, setDateOf] = useState<dayjs.Dayjs>(dayjs())
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [monthSummary, setMonthSummary] = useState<ScheduleType[]>([])
  const [staffList, setStaffList] = useState<StaffType[]>([])

  const { t } = useTranslation()

  const _fetchMonthSummary = async () => {
    setIsLoading(true)
    await fetchMonthSummary(dateOf.format('YYYY-MM-DD')).then(result => {
      setMonthSummary(result.summary)
    })
    await fetchMonthlyStaff(dateOf.format('YYYY-MM-DD')).then(result => {
      setStaffList(result)
    })

    setIsLoading(false)
  }

  const onShiftChange = async ({ staffId, dateOf }: { staffId: string; dateOf: string }) => {
    await toggleCleaningStaffShift({ staffId, dateOf })
    await fetchMonthlyStaff(dateOf).then(result => {
      setStaffList(result)
    })
  }

  const createDates = useMemo(() => {
    const startOfMonth = dateOf.startOf('month')
    const endOfMonth = dateOf.endOf('month')
    const daysInMonth = endOfMonth.diff(startOfMonth, 'day') + 1

    return Array.from({ length: daysInMonth }, (_, index) => {
      return startOfMonth.add(index, 'day').format('YYYY-MM-DD')
    })
  }, [dateOf])

  useEffect(() => {
    _fetchMonthSummary()
  }, [])

  return (
    <>
      <div css={wholeContainerStyle}>
        <SideMenu />
        <div className="mainColumn" css={mainStyle}>
          <Header title={t('Cleaning management')} />
          <ScheduleList
            dateOf={dateOf}
            setDateOf={setDateOf}
            monthSummary={monthSummary}
            staffList={staffList}
            createDates={createDates}
            onShiftChange={onShiftChange}
          />
        </div>
      </div>
      <LoadingFull isLoading={isLoading} />
    </>
  )
}

const mainStyle = css(mainColumnStyle, {
  '.sum': {
    width: 32,
    padding: '6px 9px',
    fontSize: 12,
    fontWeight: 'bold',
    backgroundColor: '#FAFAFA',
  },
})

const datesStyle = css({
  display: 'flex',
  '.date-box': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 4,
    width: 28,
    padding: '6px 9px',
    fontWeight: 'bold',
    borderLeft: '1px solid #F2F2F2',
  },
  '.day': {
    fontSize: 10,
  },
  '.date': {
    fontSize: 12,
  },
})

const summaryStyle = css({
  display: 'flex',
  'p, div': {
    border: '1px solid #F2F2F2',
  },
  p: {
    fontSize: 11,
    fontWeight: 'bold',
    borderLeft: 'none',
  },
  div: {
    width: 28,
    padding: '6px 9px',
    fontSize: 12,
    fontWeight: 'bold',
  },
})

const staffListStyle = css({
  display: 'flex',
  '.count': {
    width: 28,
    padding: '6px 9px',
    fontSize: 12,
    fontWeight: 'bold',
    border: '1px solid #F2F2F2',
    cursor: 'pointer',
  },
})

const headStyle = css({
  width: '100%',
  background: '#FAFAFA',
  borderTop: '1px solid #F2F2F2',
})

const statusTitleStyle = css({
  fontSize: 11,
  fontWeight: 'bold',
})

const subTitleStyle = css({
  fontSize: 12,
  fontWeight: 'bold',
})

// 空白や文字のスタイル
const firstItemStyle = css({
  flex: '0 0 100px',
  padding: '9px 0px 9px 16px',
})

const overWriteDateFieldStyle = css({
  'div.SingleDatePicker': {
    '.SingleDatePickerInput': {
      width: 74,
      border: 'none',
      backgroundColor: 'transparent',
      'div.SingleDatePicker_picker': {
        zIndex: 100,
      },
      '.SingleDatePickerInput_calendarIcon': { display: 'none' },
      'div.DateInput': {
        'input.DateInput_input': {
          padding: '5px 0',
          fontSize: 12,
          lineHeight: '24px',
          fontWeight: 'bold',
          color: 'rgba(0, 0, 0, 0.65)',
          letterSpacing: '0.8px',
        },
      },
    },
  },
})
