import { DateField } from '@/components/molecules/date-field'
import { css } from '@emotion/core'
import dayjs, { Dayjs } from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { mainContainerStyle } from '@/constants/layout'
import { StaffType } from '@/constants/cleaning-manager'

const weekdays = ['日', '月', '火', '水', '木', '金', '土']

export type ScheduleType = {
  date: string
  totalRoomCleaned: number
  totalRoomNotClean: number
  totalRoomNotCleanWithStay: number
  totalRoomNotCleanWithOut: number
  totalRoomInspected: number
  totalRoomDoNotDisturb: number
}

interface ScheduleListProps {
  dateOf: Dayjs
  setDateOf: React.Dispatch<React.SetStateAction<Dayjs>>
  monthSummary: ScheduleType[]
  staffList: StaffType[]
  createDates: string[]
  onShiftChange: ({ staffId, dateOf }: { staffId: string; dateOf: string }) => void
}

export const ScheduleList: React.FC<ScheduleListProps> = ({ setDateOf, createDates, dateOf, monthSummary, staffList, onShiftChange }) => {
  const { t } = useTranslation()

  const fontColorOfDay = (day: number) => {
    if (day === 0) {
      return '#F47110'
    } else if (day === 6) {
      return '#3E85CC'
    } else {
      return '#A3A3A3'
    }
  }

  let totalRoomNotCleanWithStayMonth = 0
  let totalRoomNotCleanWithOutMonth = 0
  let totalRoomCleanedMonth = 0
  let totalRoomInspectedMonth = 0
  let totalRoomDoNotDisturbMonth = 0

  return (
    <div style={{ padding: 24 }}>
      <div style={{ display: 'flex', marginBottom: 11 }}>
        <img
          style={{ cursor: 'pointer' }}
          src={require('@/static/images/arrow_left_gray.svg')}
          onClick={() => {
            setDateOf(dateOf.subtract(1, 'month'))
          }}
        />
        <DateField
          customCss={overWriteDateFieldStyle}
          value={dateOf.format('YYYY-MM-DD')}
          displayFormat={t('YYYY-MM')}
          onChange={date => date && setDateOf(dayjs(date.format('YYYY-MM-DD')))}
        />
        <img
          style={{ cursor: 'pointer' }}
          src={require('@/static/images/arrow_right_gray.svg')}
          onClick={() => {
            setDateOf(dateOf.add(1, 'month'))
          }}
        />
      </div>
      <div css={[mainContainerStyle, { background: '#fff', padding: 0 }]}>
        {!!monthSummary.length && (
          <>
            <div css={datesStyle}>
              <div css={[firstItemStyle, { paddingBlock: 0 }]} className="space"></div>
              {monthSummary.map(summary => {
                const date = dayjs(summary.date)
                const day = date.day()
                return (
                  <div key={summary.date} className="date-box">
                    <span className="day" style={{ color: fontColorOfDay(day) }}>
                      {weekdays[day]}
                    </span>
                    <span className="date">{date.date()}</span>
                  </div>
                )
              })}
              <div className="sum" style={{ display: 'flex', alignItems: 'flex-end' }}>
                {t('total of')}
              </div>
            </div>

            <div css={headStyle}>
              <p css={[firstItemStyle, subTitleStyle]}>{t('summary')}</p>
            </div>
            <div>
              <div css={summaryStyle}>
                <p css={[statusTitleStyle, firstItemStyle, { color: '#F47110' }]}>{t('cleaningManager.Stay Cleaning')}</p>
                {monthSummary.map(summary => {
                  totalRoomNotCleanWithStayMonth += summary.totalRoomNotCleanWithStay
                  return <div key={summary.date}>{summary.totalRoomNotCleanWithStay}</div>
                })}
                <div className="sum">{totalRoomNotCleanWithStayMonth}</div>
              </div>

              <div css={summaryStyle}>
                <p css={[statusTitleStyle, firstItemStyle, { color: '#F2A40B' }]}>{t('cleaningManager.Out Cleaning')}</p>
                {monthSummary.map(summary => {
                  totalRoomNotCleanWithOutMonth += summary.totalRoomNotCleanWithOut
                  return <div key={summary.date}>{summary.totalRoomNotCleanWithOut}</div>
                })}
                <div className="sum">{totalRoomNotCleanWithOutMonth}</div>
              </div>

              <div css={summaryStyle}>
                <p css={[statusTitleStyle, firstItemStyle, { color: '#3E85CC' }]}>{t('cleaningManager.Clean')}</p>
                {monthSummary.map(summary => {
                  totalRoomCleanedMonth += summary.totalRoomCleaned
                  return <div key={summary.date}>{summary.totalRoomCleaned}</div>
                })}
                <div className="sum">{totalRoomCleanedMonth}</div>
              </div>

              <div css={summaryStyle}>
                <p css={[statusTitleStyle, firstItemStyle, { color: '#7DC931' }]}>{t('cleaningManager.Inspected')}</p>
                {monthSummary.map(summary => {
                  return <div key={summary.date}>{summary.totalRoomInspected}</div>
                })}
                <div className="sum">{totalRoomInspectedMonth}</div>
              </div>

              <div css={summaryStyle}>
                <p css={[statusTitleStyle, firstItemStyle, { color: '#676767' }]}>{t('cleaningManager.Do not Disturb')}</p>
                {monthSummary.map(summary => {
                  return <div key={summary.date}>{summary.totalRoomDoNotDisturb}</div>
                })}
                <div className="sum">{totalRoomDoNotDisturbMonth}</div>
              </div>
            </div>
          </>
        )}

        {!!staffList.length && (
          <>
            <div css={headStyle}>
              <p css={[firstItemStyle, subTitleStyle]}>{t('Person in charge')}</p>
            </div>
            {staffList.map(staff => (
              <div key={staff.id} css={staffListStyle}>
                <p css={[firstItemStyle, { fontSize: 12, fontWeight: 'bold' }]}>{staff.name}</p>
                {createDates.map(date => {
                  const isShift = staff.shifts.some(shift => shift.dateOf === date)
                  return (
                    <div
                      key={date}
                      style={{ background: isShift ? '#FEFCE0' : '#fff' }}
                      className="count"
                      onClick={() => onShiftChange({ dateOf: date, staffId: staff.id })}
                    >
                      {staff.assignments.filter(assignment => assignment.dateOf === date).length}
                    </div>
                  )
                })}
                <div className="sum">{staff.assignments.length}</div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  )
}

const datesStyle = css({
  display: 'flex',
  '.date-box': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 4,
    width: 28,
    padding: '6px 9px',
    fontWeight: 'bold',
    borderLeft: '1px solid #F2F2F2',
  },
  '.day': {
    fontSize: 10,
  },
  '.date': {
    fontSize: 12,
  },
})

const summaryStyle = css({
  display: 'flex',
  'p, div': {
    border: '1px solid #F2F2F2',
  },
  p: {
    fontSize: 11,
    fontWeight: 'bold',
    borderLeft: 'none',
  },
  div: {
    width: 28,
    padding: '6px 9px',
    fontSize: 12,
    fontWeight: 'bold',
  },
})

const staffListStyle = css({
  display: 'flex',
  '.count': {
    width: 28,
    padding: '6px 9px',
    fontSize: 12,
    fontWeight: 'bold',
    border: '1px solid #F2F2F2',
    cursor: 'pointer',
  },
})

const headStyle = css({
  width: '100%',
  background: '#FAFAFA',
  borderTop: '1px solid #F2F2F2',
})

const statusTitleStyle = css({
  fontSize: 11,
  fontWeight: 'bold',
})

const subTitleStyle = css({
  fontSize: 12,
  fontWeight: 'bold',
})

// 空白や文字のスタイル
const firstItemStyle = css({
  flex: '0 0 100px',
  padding: '9px 0px 9px 16px',
})

const overWriteDateFieldStyle = css({
  'div.SingleDatePicker': {
    '.SingleDatePickerInput': {
      width: 74,
      border: 'none',
      backgroundColor: 'transparent',
      'div.SingleDatePicker_picker': {
        zIndex: 100,
      },
      '.SingleDatePickerInput_calendarIcon': { display: 'none' },
      'div.DateInput': {
        'input.DateInput_input': {
          padding: '5px 0',
          fontSize: 12,
          lineHeight: '24px',
          fontWeight: 'bold',
          color: 'rgba(0, 0, 0, 0.65)',
          letterSpacing: '0.8px',
        },
      },
    },
  },
})