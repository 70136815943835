import React, { createContext, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SerializedStyles, css } from '@emotion/core'
import {
  RoomType,
  CleaningStatusType,
  RoomUsageStatusType,
  CheckListType,
  StaffType,
  CleaningStatusValues,
  RoomUsageStatusValues,
} from '@/constants/cleaning-manager'
import i18n, { LanguageType } from '@/i18n'
import { Checkbox } from '@/components/atoms/checkbox'
import { Select } from 'antd'
import { putCleaningRoomCheckList, updateGuestRoomStatusForCleaningManager } from '@/apis/aipass'
import dayjs, { Dayjs } from 'dayjs'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { hasCleaningMemo } from '@/models/reservation/reservation-note'
import { Link, useHistory } from 'react-router-dom'
import { CleaningManagerContext } from '../room'

type ContainerProps = {
  room: RoomType
  // setIsOpenRoomDetailModal: (arg0: boolean) => void
  // setSelectedRoomId: (arg0: string) => void
}

export const RoomSp: React.FC<ContainerProps> = ({ room }) => {
  const { t } = useTranslation()
  const [cleaningStatusStyle, setCleaningStatusStyle] = useState<SerializedStyles>()
  const [roomUsageStatusText, setRoomUsageStatusText] = useState<string>('')
  const [hasInstruction, setHasInstruction] = useState<boolean>(false)
  const [hasComment, setHasComment] = useState<boolean>(false)
  const [hasPhoto, setHasPhoto] = useState<boolean>(false)
  const [roomCheckList, setRoomCheckList] = useState<Array<CheckListType & { isChecked: boolean }>>([])
  const [isDisabledCheckList, setIsDisabledCheckList] = useState<boolean>(true)
  const { errorHandler } = useErrorHandler()

  const { selectedDate } = useContext(CleaningManagerContext)

  const history = useHistory()

  const computedRoomUsageStatus = (roomUsageStatus: RoomUsageStatusType) => {
    setRoomUsageStatusText(t('cleaningManager.Vacant'))
    switch (roomUsageStatus) {
      case RoomUsageStatusType.Stay:
        setRoomUsageStatusText(t('cleaningManager.Occupied'))
        break
      case RoomUsageStatusType.Away:
        setRoomUsageStatusText(t('cleaningManager.Out'))
        break
      case RoomUsageStatusType.CheckOutScheduled:
        setRoomUsageStatusText(t('cleaningManager.Checkout Soon'))
        break
      case RoomUsageStatusType.CheckedOut:
        setRoomUsageStatusText(t('cleaningManager.Done Checkout'))
        break
    }
  }

  const computedRoomStyle = (cleaningStatus: CleaningStatusType, roomUsageStatus: RoomUsageStatusType) => {
    const isMultipleNights = ([RoomUsageStatusType.Stay, RoomUsageStatusType.Away] as Array<number>).includes(roomUsageStatus as number)

    const isCleaningRequired = ([RoomUsageStatusType.Unused, RoomUsageStatusType.Away, RoomUsageStatusType.CheckedOut] as Array<
      number
    >).includes(roomUsageStatus as number)

    switch (cleaningStatus) {
      case CleaningStatusType.NotCleaning:
        if (isMultipleNights) {
          setCleaningStatusStyle(css([stayCleaningStatusColor, !isCleaningRequired && dirtyRoomColor]))
        } else {
          setCleaningStatusStyle(css([outCleaningStatusColor, !isCleaningRequired && dirtyRoomColor]))
        }
        break
      case CleaningStatusType.Cleaned:
        setCleaningStatusStyle(cleanStatusColor)
        break
      case CleaningStatusType.Inspected:
        setCleaningStatusStyle(inspectedStatusColor)
        break
      case CleaningStatusType.DoNotDisturb:
        setCleaningStatusStyle(ddStatusColor)
        break
      default:
        setCleaningStatusStyle(dirtyStatusColor)
        break
    }
  }

  useEffect(() => {
    computedRoomUsageStatus(room.roomUsageStatus)
    computedRoomStyle(room.cleaningStatus, room.roomUsageStatus)
    setHasInstruction(room.adminComment.length > 0)
    setHasComment(room.staffReport.length > 0)
    setHasPhoto(room.photos.length > 0)
    setIsDisabledCheckList(room.cleaningStatus !== CleaningStatusType.NotCleaning)
  }, [room])

  return (
    <div onClick={() => history.push(`/cleaning/detail/${room.guestRoomId}?dateOf=${selectedDate.format('YYYY-MM-DD')}`)} css={[roomStyle, cleaningStatusStyle]}>
      <div css={roomHeaderStyle}>
        <p style={{ marginBottom: 8 }}>{room.roomNumber}</p>
        <div style={{ display: 'flex', gap: 8 }}>
          {hasPhoto && <img className="icon" src={require('@/static/images/camera_icon_gray.svg')} alt="" />}
          {(hasInstruction || hasCleaningMemo(room.nextReservation?.notes)) && (
            <img className="icon" src={require('@/static/images/cleaning_manager/icon_instruction.svg')} alt="" />
          )}
          {hasComment && <img className="icon" src={require('@/static/images/cleaning_manager/icon_comment.svg')} alt="Icon Report" />}
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div css={statusStyle}>
          <span className="cleaning">{t(CleaningStatusValues[room.cleaningStatus])}</span>
          <span className="usage">{t(RoomUsageStatusValues[room.roomUsageStatus])}</span>
        </div>
        <p style={{ fontSize: 12, fontWeight: 'bold' }}>
          IN : {room.nextReservation?.roomCheckinAt ? dayjs(room.nextReservation.roomCheckinAt).format('HH:mm') : '--:--'}
        </p>
      </div>
    </div>
  )
}

const roomHeaderStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 8,
  '.icon': {
    width: 14,
  }
})
const dirtyRoomColor = css({
  backgroundColor: '#FFFFFF'
})
const outCleaningStatusColor = css({
  borderColor: '#F2A40B',
  backgroundColor: '#FFFCDD',
  '.cleaning': {
    color: '#F2A40B !important',
  },
})
const stayCleaningStatusColor = css({
  borderColor: '#F47110',
  backgroundColor: '#FEF3F0',
  '.cleaning': {
    color: '#F47110 !important',
  },
})
const dirtyStatusColor = css({
  borderColor: '#CCCCCC',
  '.cleaning': {
    color: '#CCCCCC !important',
  },
})
const ddStatusColor = css({
  borderColor: '#676767',
  backgroundColor: '#F2F2F2',
  '.cleaning': {
    color: '#676767 !important',
  },
})
const cleanStatusColor = css({
  borderColor: '#3E85CC',
  backgroundColor: '#EFF9FE',
  '.cleaning': {
    color: '#3E85CC !important',
  },
})
const inspectedStatusColor = css({
  borderColor: '#7CC931',
  backgroundColor: '#F5FDF2',
  '.cleaning': {
    color: '#7CC931 !important',
  },
})

const roomStyle = css({
  width: '100%',
  border: '1px solid #CCCCCC',
  borderLeft: '6px solid',
  backgroundColor: '#FFFFFF',
  borderRadius: 5,
  padding: '16px 22px',
})

const statusStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  '.cleaning': {
    marginRight: 30,
    fontSize: 16,
    fontWeight: 'bold',
  },
  '.usage': {
    paddingLeft: 12,
    borderLeft: '1px solid #CCCCCC',
  },
})
