import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'

// contexts
import { AccountContext } from '@/contexts/account'

// libis
import {
  setHasRoomManagerPlugin,
  setHasSalesManagerPlugin,
  setHasMobileRequestPlugin,
  setHasCleaningManagerPlugin,
  checkActivePlugin,
} from '@/libs/plugins'
import { SideMenuItem, SideMenuItemChild } from '../molecules/side-menu-item'
import { HEADER_HEIGHT, SIDE_MENU_WIDTH } from '@/constants/layout'

type SideMenuProps = {
  pathName?: string
}

export const SideMenu: React.FC<SideMenuProps> = () => {
  const { t } = useTranslation()
  const { plugins } = useContext<any>(AccountContext)
  const [isHoverSideMenu, setIsHoverSideMenu] = useState<boolean>(false)
  // Plugin judgment
  const hasRoomManagerPlugin = setHasRoomManagerPlugin(plugins)
  const hasSalesManagerPlugin = setHasSalesManagerPlugin(plugins)
  const hasMobileRequestPlugin = setHasMobileRequestPlugin(plugins)
  const hasCleaningManagerPlugin = setHasCleaningManagerPlugin(plugins)
  const couponPluginActive = checkActivePlugin(plugins, 'CU')
  const reportsPluginActive = checkActivePlugin(plugins, 'RPT')
  const mailDeliveryPluginActive = checkActivePlugin(plugins, 'MD')
  const spaceManagementPluginActive = checkActivePlugin(plugins, 'SPM')

  return (
    <React.Fragment>
      <div css={sideMenuContainerStyle} onMouseLeave={() => setIsHoverSideMenu(false)} onMouseOver={() => setIsHoverSideMenu(true)}>
        <div className="logoContainerStyle">
          <img src={require('@/static/images/icon_logo.svg')} className="logoStyle" />
          <img src={require('@/static/images/mobile_logo.svg')} className="mobileLogoStyle" />
        </div>
        <div className="sideMenuContentStyle">
          <SideMenuItem to="/dashboard" title={t('Dashboard')} svgIconPath="icon_dashboard.svg" isHover={isHoverSideMenu} />
          <SideMenuItem
            to="/accommodation-management?tabType=checkin"
            title={t('Accommodation-management')}
            svgIconPath="icon_stay.svg"
            activePattern="/checkin/|/stay/|/checkout/|/accommodation-management|/entire-search"
            isHover={isHoverSideMenu}
          />

          <SideMenuItem
            to="/customer/list"
            title={t('Customer-management')}
            svgIconPath="icon_customer.svg"
            activePattern="/customer/"
            isHover={isHoverSideMenu}
          />

          {hasRoomManagerPlugin && (
            <>
              <SideMenuItem
                title={t('Room-manager')}
                svgIconPath="room/room.svg"
                activePattern="/room-manager/"
                isHover={isHoverSideMenu}
                to="/room-manager/daily"
              >
                <SideMenuItemChild to="/room-manager/daily" title={t('Assignment')} isHover={isHoverSideMenu}></SideMenuItemChild>
                <SideMenuItemChild to="/room-manager/indicator" title={t('Indicator')} isHover={isHoverSideMenu}></SideMenuItemChild>
              </SideMenuItem>
            </>
          )}
          {hasSalesManagerPlugin && (
            <SideMenuItem
              to="/sales-manager"
              title={t('Sales-management')}
              svgIconPath="icon_calculator.svg"
              activePattern="^/sales-manager"
              isHover={isHoverSideMenu}
            />
          )}
          {hasMobileRequestPlugin && (
            <SideMenuItem
              to="/request-manager"
              title={t('Request-management')}
              svgIconPath="mobile_request/request_icon.svg"
              isHover={isHoverSideMenu}
            />
          )}
          {spaceManagementPluginActive && (
            <SideMenuItem
              to="/space-manager"
              title={t('Space-Management')}
              svgIconPath="space_manager/space_icon.svg"
              isHover={isHoverSideMenu}
            />
          )}
          {hasCleaningManagerPlugin && (
            <SideMenuItem
              activePattern="/cleaning-manager/"
              title={t('Cleaning-management')}
              svgIconPath="cleaning_manager/icon_menu.svg"
              isHover={isHoverSideMenu}
              to="/cleaning-manager"
            >
              <SideMenuItemChild to="/cleaning-manager/status" title={t('Cleaning status')} isHover={isHoverSideMenu} />
              <SideMenuItemChild to="/cleaning-manager/schedule" title={t('Schedule')} isHover={isHoverSideMenu} />
            </SideMenuItem>
          )}
          {couponPluginActive && (
            <SideMenuItem
              to="/coupons-manager"
              title={t('Coupons')}
              svgIconPath="coupons_manager/icon_menu.svg"
              isHover={isHoverSideMenu}
            />
          )}
          {mailDeliveryPluginActive && (
            <SideMenuItem
              to="/mail-delivery-history"
              title={t('Mail delivery')}
              svgIconPath="mail-delivery/icon_menu.svg"
              isHover={isHoverSideMenu}
            />
          )}
          {reportsPluginActive && (
            <SideMenuItem
              to="/reports-manager"
              title={t('Reports')}
              svgIconPath="reports_manager/icon_menu.svg"
              isHover={isHoverSideMenu}
            />
          )}

          <SideMenuItem to="/invoice-manager" title={t('Invoice list')} svgIconPath="icon_menu_invoice.svg" isHover={isHoverSideMenu} />

          <SideMenuItem
            to="/operation-log"
            title={t('Operation Log')}
            svgIconPath="operation-log/icon_menu.svg"
            isHover={isHoverSideMenu}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

const sideMenuContainerStyle = css({
  width: SIDE_MENU_WIDTH,
  zIndex: 103,
  background: 'white',
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  position: 'sticky',
  top: 0,
  '.logoContainerStyle': {
    width: SIDE_MENU_WIDTH,
    height: HEADER_HEIGHT,
    margin: '0 auto',
    borderBottom: '1px solid #f2f2f2',
    borderRight: '1px solid #f2f2f2',
    marginRight: -1,
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    '.logoStyle': {
      width: 90,
      height: HEADER_HEIGHT,
      margin: '0 auto',
      display: 'none',
    },
    '.mobileLogoStyle': {
      width: 40,
      height: 40,
      margin: '0 auto',
      display: 'block',
    },
  },

  '.sideMenuContentStyle': {
    boxShadow: '0 3px 6px rgba(0,0,0,0.16)',
    height: '100vh',
    flex: 1,
    background: 'white',
    overflow: 'auto',
  },
  ':hover': {
    '.logoContainerStyle': {
      width: 180,
      '.logoStyle': {
        display: 'block',
      },
      '.mobileLogoStyle': {
        display: 'none',
      },
    },
    '.sideMenuContentStyle': {
      width: 179,
    },
  },
})
