import React, { useEffect } from 'react'
import { css } from '@emotion/core'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { UseFormReturn } from 'react-hook-form'
import { UpdateGuestRoomStatusForCleaningManagerRequestType } from '@/apis/aipass'
import { Checkbox } from '@/components/atoms/checkbox'
import { CheckListType, StaffType } from '@/constants/cleaning-manager'
import { InputMultipleImage } from '../../cleaning-manager/list-cleaning-room/input-multiple-image'

interface ReportFormProps {
  onSave: () => void
  useFormReturn: UseFormReturn<Required<UpdateGuestRoomStatusForCleaningManagerRequestType>>
  checkList: CheckListType[]
  staffList: StaffType[]
  saveCheckList: (id: number, isChecked: boolean) => void
  checkedIdList: number[]
}

export const ReportForm: React.FC<ReportFormProps> = ({ onSave, useFormReturn, checkList, staffList, saveCheckList, checkedIdList }) => {
  const history = useHistory()
  const { t } = useTranslation()

  const { control, register } = useFormReturn

  useEffect(() => {
    history.block(() => {
      onSave()
      return false
    })
  }, [history])

  return (
    <>
      <div css={reportingBlockStyle}>
        <div css={reportingContentStyle}>
          <p>{t('cleaningManager.CheckList')}</p>
          <ul css={{ border: '1px solid #CCCCCC', borderRadius: 6 }}>
            {checkList.map(item => (
              <li key={item.id} css={checkListItemStyle}>
                <Checkbox
                  value={checkedIdList.includes(item.id)}
                  onChange={(e) => saveCheckList(item.id, e)}
                  label={item.name}
                  style={css({ width: '100%', height: '100%' })}
                />
              </li>
            ))}
          </ul>
        </div>

        <div css={reportingContentStyle}>
          <p>{t('cleaningManager.Cleaning Report')}</p>
          <textarea
            {...register('staffReport')}
            style={{ width: '100%', height: 80, padding: '16px 20px', borderRadius: 6, borderColor: '#CCCCCC' }}
          />
        </div>

        <div css={reportingContentStyle}>
          <p>{t('Picture')}</p>
          <InputMultipleImage control={control} />
        </div>

        <div css={reportingContentStyle}>
          <p>{t('cleaningManager.CleaningStaff')}</p>
          <select {...register('cleaningStaffId')} css={normalSelectStyle}>
            <option value="">-</option>
            {staffList.map(staff => (
              <option key={staff.id} value={staff.id}>
                {staff.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  )
}

const reportingBlockStyle = css({
  padding: '32px 24px',
})

const normalSelectStyle = css({
  width: '100%',
  height: 44,
  color: '#272727',
  border: '1px solid #CCCCCC',
  borderRadius: 6,
  padding: '9px 20px',
  appearance: 'none',
  backgroundImage: `url(${require('@/static/images/arrow_down_yellow.svg')})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '95% center',
  backgroundColor: '#FFFFFF',
  display: 'block',
})

const reportingContentStyle = css({
  marginBottom: 24,
  '> p': {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 12,
  },
})

const checkListItemStyle = css({
  fontSize: 14,
  fontWeight: 'bold',
  borderBottom: '1px solid #CCCCCC',
  padding: 24,
  '&:last-of-type': {
    border: 'none',
  },
})