import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ButtonType } from '@/components/atoms/button'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@/components/atoms/modal'
import { assignCleaningStaff } from '@/apis/aipass'
import { css } from '@emotion/core'
import { CleaningManagerContext } from './list-cleaning-room/room'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { RoomListItem } from './assignment/room-list-item'

type Props = {
  rooms: object
  onClose: () => void
}
export const AssignStaffModal = ({ onClose, rooms }: Props) => {
  const [selectedGuestRoomIds, setSelectedGuestRoomIds] = useState<string[]>([])
  const [selectedStaffId, setSelectedStaffId] = useState<string>('')

  const { t } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const { selectedDate, setIsLoading, reloadRoomList, staffList } = useContext(CleaningManagerContext)

  const save = async () => {
    setIsLoading && setIsLoading(true)
    try {
      await assignCleaningStaff({ dateOf: selectedDate.format('YYYY-MM-DD'), staffId: selectedStaffId, guestRoomIds: selectedGuestRoomIds })
      reloadRoomList()
      onClose()
    } catch (e) {
      errorHandler(e)
    } finally {
      setIsLoading && setIsLoading(false)
    }
  }

  return (
    <Modal customCss={modalStyle}>
      <ModalHeader>
        {t('Person in charge')}
        {t('Assignment')}
      </ModalHeader>
      <ModalBody>
        <div css={modalBodyStyle}>
          <div css={inputTitleTextStyle}>{t('cleaningManager.CleaningStaff')}</div>
          <div style={{ marginBottom: 24 }}>
            <select
              css={[assignSelectStyle, { width: '50%' }]}
              onChange={e => {
                setSelectedStaffId(e.target.value)
              }}
            >
              <option value="">-</option>
              {staffList.map(staff => (
                <option key={staff.id} value={staff.id}>
                  {staff.name}
                </option>
              ))}
            </select>
          </div>

          <ul style={{ borderRadius: 5, border: '1px solid #CCCCCC', overflow: 'hidden' }}>
            {Object.keys(rooms).map(floorNumber => (
              <RoomListItem
                key={floorNumber}
                floorNumber={floorNumber}
                rooms={rooms}
                staffList={staffList}
                selectedGuestRoomIds={selectedGuestRoomIds}
                setSelectedGuestRoomIds={setSelectedGuestRoomIds}
              />
            ))}
          </ul>
        </div>
      </ModalBody>
      <ModalFooter customCss={modalFooterStyle}>
        <Button buttonType={ButtonType.Secondary} height={36} width={110} onClick={onClose}>
          {t('Cancel')}
        </Button>
        <Button buttonType={ButtonType.Primary} height={36} width={110} onClick={() => save()} isDisabled={!selectedStaffId}>
          {t('Save')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const modalStyle = css({
  width: 830,
  height: 603,
  top: '50%',
  // transform: 'translateY(-50%)',
})

const modalBodyStyle = css({
  padding: 32,
  paddingBottom: 42,
  background: '#fff',
  borderRadius: 5,
  '.input-block': {
    flexGrow: 1,
    width: '50%',
  },
  '.arrow-block': {
    padding: '6px 0px',
    img: { height: 18 },
  },
})

const modalFooterStyle = css({
  height: 60,
  display: 'flex',
  justifyContent: 'space-between',
})

const inputTitleTextStyle = css({
  fontSize: 14,
  fontWeight: 'bold',
  letterSpacing: '0.7px',
  color: '#272727',
  paddingBottom: 12,
})

const assignSelectStyle = css({
  width: 'max-content',
  height: '32px',
  color: '#272727',
  border: '1px solid #CCCCCC',
  borderRadius: '16px',
  padding: '6px 32px 6px 20px',
  appearance: 'none',
  backgroundImage: `url(${require('@/static/images/arrow_down_yellow.svg')})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '95% center',
  backgroundColor: '#FFFFFF',
  display: 'block',
  fontSize: '14px',
})
