import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { RoomSp } from './room'
import { CleaningManagerContext } from '../room'

interface CleaningListSpProps {
  rooms: object
}

export const CleaningListSp = ({ rooms }: CleaningListSpProps) => {
  const [cleaningStaffId, setCleaningStaffId] = useState('')

  const { t, i18n } = useTranslation()

  const { staffList } = useContext(CleaningManagerContext)

  return (
    <div css={guestRoomBlockStyle}>
      <select css={selectStyle} onChange={e => setCleaningStaffId(e.target.value)}>
        <option value="">{t('cleaningManager.CleaningStaff')}</option>
        {staffList?.map(staff => (
          <option key={staff.id} value={staff.id}>
            {staff.name}
          </option>
        ))}
      </select>

      {Object.keys(rooms).map(floorNumber => (
        <React.Fragment key={floorNumber}>
          {rooms[floorNumber]
            .filter(room => !cleaningStaffId || room.cleaningStaffId === cleaningStaffId)
            .map(room => (
              <RoomSp key={room.guestRoomId} room={room} />
            ))}
        </React.Fragment>
      ))}
    </div>
  )
}

const guestRoomBlockStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  padding: 24,
  overflowY: 'auto',
})

const selectStyle = css({
  width: '100%',
  appearance: 'none',
  padding: '12px 24px',
  backgroundImage: `url(${require('@/static/images/arrow_down_yellow.svg')})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '90% center',
  border: '1px solid #CCCCCC',
  borderRadius: 5,
})
