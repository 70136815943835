import React, { useContext } from 'react'
import { Dayjs } from 'dayjs'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { Calendar } from '@/components/organisms/cleaning-manager/day-stats/calendar'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { CleaningManagerContext } from '../list-cleaning-room/room'
import { CleaningListSp } from '../list-cleaning-room/sp'
import { HeaderSp } from '../../cleaner/sp/header-sp'


interface SpIndexProps {
  rooms: object
  generalNoteContent: string
  setSelectedDate: (arg0: Dayjs) => void
}

export const SpIndex: React.FC<SpIndexProps> = ({ setSelectedDate, generalNoteContent, rooms }) => {

  const { t } = useTranslation()

  const { selectedDate } = useContext(CleaningManagerContext)

  return (
    <div css={blockStyle}>
      <HeaderSp title={t('Cleaning management')} />
      <Calendar selectedDate={selectedDate} setSelectedDate={setSelectedDate} />

      <div style={{ marginTop: 24 }}>
        <Tabs>
          <TabList>
            <Tab>{t('cleaningManager.Notice')}</Tab>
            <Tab>{t('GuestRoomAssign')}</Tab>
          </TabList>
  
          <TabPanel>
            <p style={{ padding: 24 }}>{generalNoteContent}</p>
          </TabPanel>
          <TabPanel>
            <CleaningListSp rooms={rooms} />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  )
}

const blockStyle = css({
  '.react-tabs__tab-list': {
    display: 'flex',
    border: 'none',
  },
  '.react-tabs__tab': {
    width: '50%',
    border: 'none',
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#AFAFAF',
    paddingBottom: 14,
    '&--selected': {
      color: '#F2A40B',
      borderBottom: '2px solid #F2A40B',
    },
  },
})
